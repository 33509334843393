import React, { useState, useEffect } from 'react';
import css from './ListingDetails.module.css';
import { FormattedMessage, intlShape } from '../../../../util/reactIntl';
import Cookies from 'universal-cookie';
import animalIcon from '../../../../assets/icons/projectTypesIcon/animal.png';
import natureIcon from '../../../../assets/icons/projectTypesIcon/environment.png';
import equalityIcon from '../../../../assets/icons/projectTypesIcon/equality.png';
import healthIcon from '../../../../assets/icons/projectTypesIcon/health.png';
import sportsIcon from '../../../../assets/icons/projectTypesIcon/sports.png';
import educationIcon from '../../../../assets/icons/projectTypesIcon/education.png';
import { useIntl } from 'react-intl';

const ListingDetailsHeader = props => {
  const [pageLanguage, setpageLanguage] = useState('en');
  const [isMobile, setIsMobile] = useState(false);
  const {
    peopleAmount,
    food,
    amenities,
    category,
    roomTypeRaw: roomType,
  } = props.currentListing.attributes.publicData;
  const hostNameRaw = props.currentListing.author.attributes.profile.displayName;
  const hostFirstName = hostNameRaw.split(' ')[0];
  const cookies = new Cookies();
  const intl = useIntl();
  const yourHost = intl.formatMessage({ id: 'ListingDetails.yourHost' });

  let accomodationTypeTranslationId = '';
  if (props.projectAccomodationType !== null) {
    accomodationTypeTranslationId = props.projectAccomodationType.props.id;
  }

  let currentRoomtypeTranslationId = '';
  if (props.ProjectRoomtype) {
    currentRoomtypeTranslationId = props.ProjectRoomtype.props.id;
  }

  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const language = cookies.get('language');
    setpageLanguage(language);
  }, []);

  const charValues = [
    {
      value: 'Tierschutz',
      text: <FormattedMessage id="filter.category.animalCare" />,
      icon: animalIcon,
    },
    {
      value: 'Naturschutz',
      text: <FormattedMessage id="filter.category.environment" />,
      icon: natureIcon,
    },

    {
      value: 'Equality',
      text: <FormattedMessage id="filter.category.equality" />,
      icon: equalityIcon,
    },

    {
      value: 'Health',
      text: <FormattedMessage id="filter.category.health" />,
      icon: healthIcon,
    },

    {
      value: 'Sports',
      text: <FormattedMessage id="filter.category.sports" />,
      icon: sportsIcon,
    },
    {
      value: 'Bildung',
      text: <FormattedMessage id="filter.category.education" />,
      icon: educationIcon,
    },
  ];

  const GetCharacteristics = () => {
    const firstChar = category && getCorrectChar(category[0]);
    const secondChar = category && getCorrectChar(category[1]);

    return (
      <div className={css.characteristicsWrapper}>
        <div className={css.characteristics}>
          <img
            style={{ height: '30px', width: '30px' }}
            src={firstChar?.icon}
            alt="socialbnb projects characteristics"
          />
          <span>
            <p className={css.charTxt}>{firstChar?.text}</p>
          </span>
        </div>
        {secondChar && secondChar.icon && (
          <div className={css.characteristics}>
            <img
              style={{ height: '30px', width: '30px' }}
              src={secondChar.icon}
              alt="socialbnb projects characteristics"
            />
            <span>
              <p className={css.charTxt}>{secondChar.text}</p>
            </span>
          </div>
        )}
      </div>
    );
  };

  const getCorrectChar = c => {
    let correctData = {};

    if (c) {
      for (let i = 0; i < charValues.length; i++) {
        if (charValues[i].value == c) {
          correctData = charValues[i];
        }
      }
    }
    return correctData;
  };

  const GetIncludedFood = () => {
    let includedFood = '';

    const foodData = [
      {
        id: 'vegetarian_options',
        text: <FormattedMessage id="ListingDetails.food.veg" />,
      },
      {
        id: 'vegan_options',
        text: <FormattedMessage id="ListingDetails.food.vegan" />,
      },
      {
        id: 'coffee_tee',
        text: <FormattedMessage id="ListingDetails.food.coffeeTea" />,
      },
      {
        id: 'dinner_inclusive',
        text: <FormattedMessage id="ListingDetails.food.dinner" />,
      },
      {
        id: 'breakfast_inclusive',
        text: <FormattedMessage id="ListingDetails.food.breakfast" />,
      },
      {
        id: 'lunch_inclusive',
        text: <FormattedMessage id="ListingDetails.food.lunch" />,
      },
    ];
    if (food) {
      for (let i = 0; i < foodData.length; i++) {
        for (let j = 0; j < food.length; j++) {
          if (food[j] === foodData[i].id) {
            includedFood = foodData[i];
          }
        }
      }
    }

    return (
      <>
        {includedFood && (
          <div className={css.subSections}>
            <span>&#8226;</span>
            <span> {includedFood.text}</span>
          </div>
        )}
      </>
    );
  };
  const GetAmenities = () => {
    let bathroomSituation = null;

    if (amenities) {
      for (let i = 0; i < amenities.length; i++) {
        if (amenities[i] === 'privat_bathroom') {
          bathroomSituation = (
            <FormattedMessage id="ListingDetails.bathroomSituation.prv_bathroom" />
          );
          break;
        }
        if (amenities[i] === 'shared_bathroom') {
          bathroomSituation = (
            <FormattedMessage id="ListingDetails.bathroomSituation.sh_bathroom" />
          );
          break;
        }
        if (amenities[i] === 'private_kitchen') {
          bathroomSituation = <FormattedMessage id="ListingDetails.bathroomSituation.kitchen" />;
        }
      }
    }

    return (
      <>
        {bathroomSituation && (
          <div className={css.subSections}>
            {' '}
            <span>&#8226;</span>
            <span> {bathroomSituation}</span>
          </div>
        )}
      </>
    );
  };
  let accomodationTypeRaw = props?.currentListing?.attributes?.publicData?.accomodationtype ?? '';
  const projectAccomodationType =
    accomodationTypeRaw === 'bnb' || accomodationTypeRaw === 'B&B'
      ? intl.formatMessage({ id: 'accomodationDisplay.B&B' })
      : accomodationTypeRaw === 'glamping'
      ? intl.formatMessage({ id: 'accomodationDisplay.glamping' })
      : accomodationTypeRaw === 'hostel'
      ? intl.formatMessage({ id: 'accomodationDisplay.hostel' })
      : accomodationTypeRaw === 'hotel'
      ? intl.formatMessage({ id: 'accomodationDisplay.hotel' })
      : accomodationTypeRaw === 'homestay'
      ? intl.formatMessage({ id: 'accomodationDisplay.homestay' })
      : accomodationTypeRaw === 'holiday'
      ? intl.formatMessage({ id: 'accomodationDisplay.holiday' })
      : accomodationTypeRaw === 'apartment'
      ? intl.formatMessage({ id: 'accomodationDisplay.apartment' })
      : accomodationTypeRaw === 'tinyHouse'
      ? intl.formatMessage({ id: 'accomodationDisplay.tinyHouse' })
      : accomodationTypeRaw === 'camping'
      ? intl.formatMessage({ id: 'accomodationDisplay.camping' })
      : accomodationTypeRaw === 'banglow'
      ? intl.formatMessage({ id: 'accomodationDisplay.banglow' })
      : null;

  let roomTypeRaw = props?.roomTypeRaw ?? '';

  //is stay
  const projectRoomtype =
    roomTypeRaw == null
      ? null
      : props.roomTypeRaw === 'singlebedroom'
      ? intl.formatMessage({ id: 'roomtypes.singlebedroom' })
      : props.roomTypeRaw === 'twobedroom'
      ? intl.formatMessage({ id: 'roomtypes.twobedroom' })
      : roomTypeRaw === 'doublebedroom' || roomTypeRaw === 'doubleRoom'
      ? intl.formatMessage({ id: 'roomtypes.doublebedroom' })
      : props.roomTypeRaw === 'shared_bedroom'
      ? intl.formatMessage({ id: 'roomtypes.shared_bedroom' })
      : props.roomTypeRaw === 'multi_bedroom'
      ? intl.formatMessage({ id: 'roomtypes.multi_bedroom' })
      : props.roomTypeRaw === 'entire_accomodation'
      ? intl.formatMessage({ id: 'roomtypes.entire_accomodation' })
      : props.roomTypeRaw === 'tent'
      ? intl.formatMessage({ id: 'roomtypes.tent' })
      : null;

  //is experience
  const experienceType = props?.currentListing?.attributes?.title.split('• ')[1];
  const experienceTitle =
    experienceType === '2 Days 1 Night'
      ? intl.formatMessage({ id: 'experienceTitle.2d1n' })
      : experienceType === '3 Days 2 Nights'
      ? intl.formatMessage({ id: 'experienceTitle.3d2n' })
      : experienceType === '4 Days 3 Nights'
      ? intl.formatMessage({ id: 'experienceTitle.4d3n' })
      : experienceType === '5 Days 4 Nights'
      ? intl.formatMessage({ id: 'experienceTitle.5d4n' })
      : experienceType === '6 Days 5 Nights'
      ? intl.formatMessage({ id: 'experienceTitle.6d5n' })
      : experienceType === '1 Week'
      ? intl.formatMessage({ id: 'experienceTitle.1w' })
      : experienceType === '2 Weeks'
      ? intl.formatMessage({ id: 'experienceTitle.2w' })
      : experienceType === '3 Weeks'
      ? intl.formatMessage({ id: 'experienceTitle.3w' })
      : null;
  const overnightStay = intl.formatMessage({ id: 'listingDetails.overnightstay' });

  return (
    <div className={css.listingDetailsWrapper}>
      <div className={css.listingInfoWrapper}>
        <div className={css.titleNcharacteristics}>
          <h2 style={{ display: 'flex' }}>
            {props.isExperienceType
              ? experienceTitle + ',  ' + yourHost + ' ' + hostFirstName
              : projectRoomtype +
                ' ' +
                projectAccomodationType +
                ', ' +
                yourHost +
                ' ' +
                hostFirstName}
          </h2>

          <div className={css.accomodationFeatures}>
            {!props.isExperienceType ? (
              <>
                <div>
                  <span className={css.person}>
                    <span></span>
                    {peopleAmount}
                    <FormattedMessage
                      id={peopleAmount > 1 ? 'ListingDetails.persons' : 'ListingDetails.person'}
                    />
                  </span>
                </div>

                <GetIncludedFood />
                <GetAmenities />
              </>
            ) : (
              <>{'Impact Experience - ' + overnightStay + projectAccomodationType}</>
            )}
          </div>
        </div>

        <hr />

        <div className={css.charWrapper}>
          <GetCharacteristics />
        </div>
      </div>
    </div>
  );
};

export default ListingDetailsHeader;
