import React, { useState } from 'react';
import { FormattedMessage } from '../../../../util/reactIntl';
import css from './AccomodationDetails.module.css';
import { ListingCardSmall } from '../../../../components';
import { IconArrowHead } from '../../../../components';
import Modal from '../Utils/Modal/Modal';
import { useSelector } from 'react-redux';
import BulletPointsFormatter from '../../../../util/formatters/bulletPointsFormatter/bulletPointsFormatter';
import activityImg from '../../../../assets/Experiencehighlights/activity.png';
import Activities from '../Activities/Activities';
import foodImg from '../../../../assets/Experiencehighlights/food.png';
import impactImg from '../../../../assets/Experiencehighlights/impact.png';

const AccomodationDetails = props => {
  const [showFullText, setShowFullText] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showHighlightsFullText, setShowHighlightsFullText] = useState(false);
  const [isHighlightsModalOpen, setIsHighlightsModalOpen] = useState(false);

  const { accomodationDescription, accomodationDescription_de } =
    props.currentListing &&
    props.currentListing.attributes.publicData &&
    props.currentListing.attributes.publicData;

  const {
    highlights,
    highlights_de,
    ActivityHighlights,
    ActivityHighlights_de,
    FoodHighlights_de,
    FoodHighlights,
    ImpactHighlights,
    ImpactHighlights_de,
  } =
    props.currentListing &&
    props.currentListing.attributes.publicData &&
    props.currentListing.attributes.publicData;

  const currentLanguage = useSelector(state => state.ui.language);
  let listingsItems = [];
  const isExperienceType =
    props.currentListing.attributes?.publicData?.listingType == 'impact-experience';

  if (isExperienceType && props.listings.length > 0) {
    props.listings.map(listing => {
      if (listing.attributes?.publicData?.listingType == 'impact-experience') {
        listingsItems.push(listing);
      }
    });
  } else {
    props.listings.map(listing => {
      if (listing.attributes?.publicData?.listingType !== 'impact-experience') {
        listingsItems.push(listing);
      }
    });
  }
  const toggleShowFullText = () => {
    setShowFullText(!showFullText);
    setIsModalOpen(!isModalOpen);
  };

  const toggleShowHighlightsFullText = () => {
    setShowHighlightsFullText(!showHighlightsFullText);
    setIsHighlightsModalOpen(!isHighlightsModalOpen);
  };

  const toAboutProject = () => {
    if (typeof window !== 'undefined') {
      const aboutProjectComponent = document.getElementById('aboutProjectComponent');
      if (aboutProjectComponent) {
        aboutProjectComponent.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const AccomodationDescription = () => {
    let description = '';
    if (accomodationDescription || accomodationDescription_de) {
      description = currentLanguage == 'en' ? accomodationDescription : accomodationDescription_de;
    }

    const txtChecked = typeof description === 'string' && description.length > 0 ? description : '';
    const arrayOfStrings = txtChecked ? description.split('. ').filter(Boolean) : [];
    const shortenedText = arrayOfStrings.slice(0, 4).join('.');
    const sentencesWithDot = arrayOfStrings.map(sentence => sentence.trim());

    const formattedShotSentece = <BulletPointsFormatter text={shortenedText + '...'} />;
    const formattedFullSentece = <BulletPointsFormatter text={description} />;
    const isExperienceType =
      props.currentListing?.attributes?.publicData?.listingType == 'impact-experience';
    return (
      <div className={css.descriptionWrapper}>
        {props.isExperienceType ? (
          <Activities
            activitiesEn={props.activitiesEn}
            activitiesDe={props.activitiesDe}
            isExperienceType={props.isExperienceType}
          />
        ) : (
          <>
            <h2>
              {isExperienceType ? (
                <FormattedMessage id="editListingPage.key5" />
              ) : (
                <FormattedMessage id="ListingDetails.descriptionTitle" />
              )}
            </h2>
            {arrayOfStrings.length > 4 ? (
              showFullText ? (
                <>
                  {isModalOpen && (
                    <Modal isOpen={isModalOpen} onClose={toggleShowFullText}>
                      <div style={{ whiteSpace: 'pre-wrap' }}> {formattedFullSentece} </div>
                    </Modal>
                  )}{' '}
                </>
              ) : (
                <>
                  <div className={css.shortenedText}>{formattedShotSentece}</div>
                  <button className={css.btn} onClick={toggleShowFullText}>
                    <p className={css.btnText}>
                      {' '}
                      <FormattedMessage id="EditListingOffersForm.activities.showMore" />
                    </p>
                    <p className={css.Gt}>&gt;</p>
                  </button>
                </>
              )
            ) : (
              <div style={{ whiteSpace: 'pre-wrap' }}>{formattedFullSentece}</div>
            )}
          </>
        )}
      </div>
    );
  };

  const AccomodationHighlights = () => {
    let description = '';
    if (highlights || highlights_de) {
      description = currentLanguage == 'en' ? highlights : highlights_de;
    }

    const Activity = currentLanguage == 'en' ? ActivityHighlights : ActivityHighlights_de;
    const Food = currentLanguage == 'en' ? FoodHighlights : FoodHighlights_de;
    const Impact = currentLanguage == 'en' ? ImpactHighlights : ImpactHighlights_de;

    const ActivityFormatted = <BulletPointsFormatter text={Activity} />;
    const FoodFormatted = <BulletPointsFormatter text={Food} />;
    const ImpactFormatted = <BulletPointsFormatter text={Impact} />;

    if (!Activity && !Food && !Impact) return <></>;

    return (
      <div className={css.descriptionWrapper}>
        <h2> Highlights</h2>
        <div className={css.highlightCard}>
          <img src={activityImg} className={css.highlightImg} />
          <div className={css.highlightCardcontent}>
            <h2 className={css.highlightTitle}>
              <FormattedMessage id="editListDescription.highlightActivityTitle" />
            </h2>
            <div className={css.highlightText} style={{ whiteSpace: 'pre-wrap' }}>
              {ActivityFormatted}
            </div>
          </div>
        </div>

        <div className={css.highlightCard}>
          <img src={foodImg} className={css.highlightImg} />
          <div className={css.highlightCardcontent}>
            <h2 className={css.highlightTitle}>
              <FormattedMessage id="editListDescription.highlightsFoodTitle" />
            </h2>
            <div className={css.highlightText}>{FoodFormatted}</div>
          </div>
        </div>

        <div className={css.highlightCard}>
          <img src={impactImg} className={css.highlightImg} />
          <div className={css.highlightCardcontent}>
            <h2 className={css.highlightTitle}>
              <FormattedMessage id="editListDescription.highlightsImpactInfo" />
            </h2>
            <div className={css.highlightText} style={{ whiteSpace: 'pre-wrap' }}>
              {ImpactFormatted}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const OvernightStaySupport = () => {
    const supportSmallText =
      currentLanguage == 'en'
        ? props?.currentListing?.author?.attributes?.profile?.publicData?.projectImpact
        : props?.currentListing?.author?.attributes?.profile?.publicData?.projectImpact_de;
    const formattedSupportSmallText = <BulletPointsFormatter text={supportSmallText} />;
    return (
      <div className={css.stayBtnWrapper}>
        <h2>
          {isExperienceType ? (
            <FormattedMessage id="listingPage.experienceSupport" />
          ) : (
            <FormattedMessage id="ListingDetails.staySupportTitle" />
          )}
        </h2>
        <div className={css.supportText} style={{ whiteSpace: 'pre-wrap' }}>
          {formattedSupportSmallText}
        </div>
        <div onClick={toAboutProject} className={css.supportMoreBtn}>
          <div className={css.arrowDownIcon}>
            <IconArrowHead direction="down" color="#eb7242" />
          </div>
          <p className={css.btnTxt} data-elbaction="click:click accm-tellmemore">
            <FormattedMessage id="ListingDetails.stayBtn" />
          </p>
        </div>
      </div>
    );
  };
  const GetRoomTypesCard = () => {
    if (listingsItems.length < 2) {
      return <></>;
    } else
      return (
        <div className={css.roomtypeWrapper}>
          <h2>
            {isExperienceType ? (
              <FormattedMessage id="listingPage.LookingForNewPackages" />
            ) : (
              <FormattedMessage id="listingDetails.roomtype.title" />
            )}
          </h2>
          <div className={css.listingsContainer}>
            <div className={css.listings}>
              <ul className={css.listings}>
                {listingsItems.map(l =>
                  l?.id?.uuid != props?.currentListing?.id?.uuid ? (
                    <li
                      className={css.listing}
                      key={l.id.uuid}
                      data-elbaction={`click:click accm-roomtype-${l.attributes.description}`}
                    >
                      <ListingCardSmall listing={l} />
                    </li>
                  ) : null
                )}
              </ul>
            </div>
            <br />
          </div>
        </div>
      );
  };

  return (
    <div className={css.aboutAccomodationWrapper}>
      {isExperienceType ? <AccomodationHighlights /> : <></>}
      <AccomodationDescription />
      <OvernightStaySupport />
      <hr className={css.verticalLine} />
      <GetRoomTypesCard />
    </div>
  );
};

export default AccomodationDetails;
