import React, { useState, useEffect } from 'react';

import { FormattedMessage } from '../../../../util/reactIntl';
import css from './Activities.module.css';
import Modal from '../Utils/Modal/Modal.jsx';
import { useSelector } from 'react-redux';
import BulletPointsFormatter from '../../../../util/formatters/bulletPointsFormatter/bulletPointsFormatter.jsx';

const Activities = ({ activitiesEn, activitiesDe, isExperienceType }) => {
  const [showFullText, setShowFullText] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleShowFullText = () => {
    setShowFullText(!showFullText);
    setIsModalOpen(!isModalOpen);
  };
  const currentLanguage = useSelector(state => state.ui.language);
  const ActivitiesText =
    currentLanguage === 'de' && activitiesDe !== 'Übersetzung hinzufügen'
      ? activitiesDe
      : activitiesEn;

  const ActivitiesTextChecked =
    typeof ActivitiesText === 'string' && ActivitiesText.length > 0 ? ActivitiesText : '';

  const arrayOfStrings = ActivitiesTextChecked
    ? ActivitiesTextChecked.split('.').filter(Boolean)
    : [];

  const shortenedText = arrayOfStrings.slice(0, 5).join('. ');
  const sentencesWithDot = arrayOfStrings.map(sentence => sentence.trim() + '.');

  const isTextTooLong = arrayOfStrings.length >= 5;
  const formattedShotSentece = <BulletPointsFormatter text={shortenedText + '...'} />;
  const formattedFullSentece = <BulletPointsFormatter text={ActivitiesText} />;

  return (
    <div
      className={`${css.activities} ${showFullText ? css.showFullText : ''}`}
      data-elbaction="visible:vis accm-activities"
    >
      <h2>
        {isExperienceType ? (
          <FormattedMessage id="editListingPage.key5" />
        ) : (
          <FormattedMessage id="EditListingOffersForm.activitiesLabel" />
        )}
      </h2>
      {isTextTooLong ? (
        <>
          {showFullText ? (
            <>
              {isModalOpen && (
                <Modal
                  isOpen={isModalOpen}
                  onClose={toggleShowFullText}
                  classNameButton="buttonActivities"
                >
                  {formattedFullSentece}
                </Modal>
              )}
            </>
          ) : (
            <>
              <div className={css.shortenedText} data-elbaction="visible:vis accm-readmorebtn">
                {formattedShotSentece}
              </div>
              <button
                className={css.btn}
                onClick={toggleShowFullText}
                data-elbaction="click:click accm-readmorebutton"
              >
                {/* <img className={css.btnImg} src={info} /> */}
                <p className={css.btnText}>
                  <FormattedMessage id="EditListingOffersForm.activities.showMore" />
                </p>
                <p className={css.Gt}>&gt;</p>
              </button>
            </>
          )}
        </>
      ) : (
        formattedFullSentece
      )}
      <br/>
    </div>
  );
};

export default Activities;
