import React from 'react';
import { useSelector } from 'react-redux';
import BulletPointsFormatter from '../../../../util/formatters/bulletPointsFormatter/bulletPointsFormatter';

const HouseRules = ({ houseRules, houseRulesDe }) => {
  const currentLanguage = useSelector(state => state.ui.language);

  const houseRulesChecked = houseRules && typeof houseRules === 'string' ? houseRules : '';
  const houseRulesCheckedDe = houseRulesDe && typeof houseRulesDe === 'string' ? houseRulesDe : '';

  const houseRulesToRender =
    currentLanguage === 'de' && houseRulesDe !== 'Übersetzung hinzufügen' ? (
      <>
        {' '}
        <BulletPointsFormatter text={houseRulesCheckedDe} />
      </>
    ) : (
      <>
        {' '}
        <BulletPointsFormatter text={houseRulesChecked} />
      </>
    );
  return <div>{houseRulesToRender}</div>;
};

export default HouseRules;
