import React from 'react';
import css from './bulletPointsFormatter.module.css';

const BulletPointsFormatter = ({ text }) => {
  if (!text || text?.length == 0 || typeof text !== 'string') {
    return <></>;
  }

  const bulletPointChars = new Set(['-', '•', '+']);
  const lines = text.split('\n');

  const processTextFormatting = (str) => {
    const boldRegex = /(\*\*|__)(.+?)\1/g;
    const italicRegex = /(\*|_)(.+?)\1/g;

    let lastIndex = 0;
    const result = [];
    let match;

    while ((match = boldRegex.exec(str)) !== null) {
      if (match.index > lastIndex) {
        result.push(str.slice(lastIndex, match.index));
      }
      result.push(<strong key={result.length}>{match[2]}</strong>);
      lastIndex = match.index + match[0].length;
    }

    if (lastIndex < str.length) {
      result.push(str.slice(lastIndex));
    }

    const finalResult = [];
    lastIndex = 0;

    result.forEach((segment, index) => {
      if (typeof segment === 'string') {
        while ((match = italicRegex.exec(segment)) !== null) {
          if (match.index > lastIndex) {
            finalResult.push(segment.slice(lastIndex, match.index));
          }
          finalResult.push(<em key={`${index}-${finalResult.length}`}>{match[2]}</em>);
          lastIndex = match.index + match[0].length;
        }

        if (lastIndex < segment.length) {
          finalResult.push(segment.slice(lastIndex));
        }
      } else {
        finalResult.push(segment);
      }
      lastIndex = 0;
    });

    return finalResult;
  };

  const formattedLines = lines.map((line, index) => {
    const trimmedLine = line.trim();
    if (bulletPointChars.has(trimmedLine.charAt(0))) {
      const [bullet, ...rest] = trimmedLine.split(/\s+/);
      return (
        <div key={index} className={css.bulletPoint}>
          {bullet} {processTextFormatting(rest.join(' '))}
        </div>
      );
    } else {
      return <div key={index}>{processTextFormatting(trimmedLine)}</div>;
    }
  });

  return <div className={css.formattedText}>{formattedLines}</div>;
};

export default BulletPointsFormatter;
