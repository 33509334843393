import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from '../../../../util/reactIntl';
import css from './ExperienceMockUpSection.module.css';
import BulletPointsFormatter from '../../../../util/formatters/bulletPointsFormatter/bulletPointsFormatter';

const ExperienceMockUpSection = () => {
  const [userVersion, setUserVersion] = useState(null);
  const intl = useIntl();
  useEffect(() => {
    const itemStr = localStorage.getItem('userVersion');
    if (itemStr) {
      const item = JSON.parse(itemStr);
      const now = new Date();
      if (now.getTime() <= item.expiry) {
        setUserVersion(item.value);
      }
    }
  }, []);
  const textToFormat = intl.formatMessage({ id :"ListingPage.mockupSection.textB"});
  return (
    <div className={css.section}>
      {userVersion === 'versionA' && (
        <div data-elbaction="visible:vis accm-mockupA">
          <h2>
            <FormattedMessage id="ListingPage.mockupSection.titleA" />
          </h2>
          <FormattedMessage id="ListingPage.mockupSection.textA" />
          <button
            className={css.openBtn}
            data-elbaction="click:click accm-btnockupA"
            onClick={function() {
              if (typeof window !== 'undefined') {
                window.open('/p/impact-experiences', '_blank', 'noopener,noreferrer');
              }
            }}
          >
            <FormattedMessage id="ListingPage.mockupSection.buttonA" />
          </button>
        </div>
      )}
      {userVersion === 'versionB' && (
        <div data-elbaction="visible:vis accm-mockupB">
          <h2>
            <FormattedMessage id="ListingPage.mockupSection.titleB" />
          </h2>
          <BulletPointsFormatter text={textToFormat} />
          <button
            className={css.openBtn}
            data-elbaction="click:click accm-btnmockupB"
            onClick={function() {
              if (typeof window !== 'undefined') {
                window.open('/p/impact-experiences', '_blank', 'noopener,noreferrer');
              }
            }}
          >
            <FormattedMessage id="ListingPage.mockupSection.buttonB" />
          </button>
        </div>
      )}
    </div>
  );
};

export default ExperienceMockUpSection;
