import css from './faqDisplay.module.css';
import React, { useState } from 'react';

const QuestionsComponent = ({ questions, currentLanguage }) => {
  const [expandedQuestion, setExpandedQuestion] = useState(null);

  const handleQuestionClick = question => {
    setExpandedQuestion(expandedQuestion === question ? null : question);
  };

  if (!questions || questions == undefined) {
    return <></>;
  } else {
    return (
      <div className={css.faqContainer}>
        {questions.map((question, index) => (
          <div key={index} className={css.questionContainer}>
            <div className={css.question} onClick={() => handleQuestionClick(question)}>
              <span>{currentLanguage == 'de' ? question.question_de : question.question}</span>
              <span className={`${css.arrow} ${expandedQuestion === question ? css.expanded : ''}`}>
                &#9654;
              </span>
            </div>
            {expandedQuestion === question && (
              <div className={css.answer}>
                {currentLanguage == 'de' ? question.answer_de : question.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }
};

export default QuestionsComponent;
