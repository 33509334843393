import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from '../../../../util/reactIntl';
import BulletPointsFormatter from '../../../../util/formatters/bulletPointsFormatter/bulletPointsFormatter';

const PackList = ({ packListEn, packListDe, packList }) => {
  const currentLanguage = useSelector(state => state.ui.language);

  const packListToRender =
    currentLanguage === 'de' && packListDe !== 'Übersetzung hinzufügen' ? (
      <BulletPointsFormatter text={packListDe} />
    ) : (
      <BulletPointsFormatter text={packListEn} />
    );

  return <div>{packListToRender}</div>;
};

export default PackList;
